import { axiosNotes } from "../axios/notes_axios";

export const noteGet = async (id: string): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosNotes.get(`notes/${id}`);
  } catch (error) {}
  if (response && response.status === 200) {
    result = noteResponseFormat(response.data);
  }

  return result;
};

const noteResponseFormat = (note: any): any => {
  const result = { ...note, id: note._id };

  return result;
};

export const noteListGet = async (): Promise<any> => {
  let result: any = null;
  let response: any;
  try {
    response = await axiosNotes.get(`notes`);
  } catch (error) {}
  if (response && response.status === 200) {
    result = response.data;

    result = result.map((item: any) => noteResponseFormat(item));
  }

  return result;
};
