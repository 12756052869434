import React from "react";
import styled from "styled-components";
import { NoteResult } from "common/note/dto/NoteResult";
import NoteItemView from "./NoteItemView";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import { NavLink } from "react-router-dom";
import { noteLinkSingle } from "common/note/note_link_common";
import { desHide } from "components/common/style/media_query/hide";

type NoteItemProps = {
  note: NoteResult;
  onNoteClick: (note: NoteResult) => void;
};

const NoteItem = ({ note, onNoteClick }: NoteItemProps) => {
  return (
    <DivItem>
      <Div onClick={() => onNoteClick(note)}>
        <NoteItemView note={note} />
      </Div>
      <NavLinkMob to={noteLinkSingle(note.id)}>
        <NoteItemView note={note} />
      </NavLinkMob>
    </DivItem>
  );
};

const DivItem = styled.div`
  font-size: 1.1rem;
`;

const Div = styled.div`
  display: none;
  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    display: block;
  }
`;

const NavLinkMob = desHide(styled(NavLink)`
  display: block;
  &&& {
    text-decoration: none;
  }
`);

export default NoteItem;
