import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type DialogContextValue = Function;

export type ConfirmDialogOptions = {
  title?: string;
  message?: string;
};

const ConfirmDialog = createContext<DialogContextValue>(() => null);

type ConfirmDialogProviderProps = { children: React.ReactNode };

export function ConfirmDialogProvider({
  children,
}: ConfirmDialogProviderProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ConfirmDialogOptions>({
    title: "",
    message: "Are you sure",
  });
  const fn = useRef<Function>(() => null);

  const confirm = useCallback(
    (optionsData: ConfirmDialogOptions) => {
      return new Promise((resolve) => {
        setOpen(true);
        setOptions(optionsData);
        fn.current = (choice: any) => {
          resolve(choice);
          setOpen(false);
        };
      });
    },
    [setOpen]
  );

  const handleCancel = () => {
    setOpen(false);
    fn.current(false);
    console.log("handleCancel");
  };

  const handleConfirm = () => {
    setOpen(false);
    fn.current(true);
    console.log("handleConfirm");
  };

  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{options.title}</DialogTitle>
        <DialogContent sx={{ minWidth: "400px" }}>
          {options.message && (
            <DialogContentText>{options.message}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ConfirmDialog.Provider>
  );
}

export default function useConfirm() {
  return useContext(ConfirmDialog);
}
