import { NoteResult } from "common/note/dto/NoteResult";
import SearchResultList from "components/common/SearchResultList";
import NoteRead from "../Note/NoteRead";
import { useState, useEffect } from "react";
import { useNoteSearch } from "hooks/note/useNoteSearch";
import styled from "styled-components";
import { noteInList } from "./NoteList.logic";
import NoteItem from "./NoteItem";
import NoNoteInfo from "./NoNoteInfo";
import { mobHide } from "components/common/style/media_query/hide";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import { isNoteSame } from "common/note/note_common";

const NoteList = () => {
  const noteListResult = useNoteSearch();

  const [selectedNote, setSelectedNote] = useState<NoteResult | null>(null);

  // Set the first note to be selected if available.
  // Exclude note from the view if it not anymore in result list.
  useEffect(() => {
    if (selectedNote === null) {
      if (noteListResult !== null && noteListResult.length >= 0) {
        setSelectedNote(noteListResult[0]);
      }
    } else {
      if (!noteInList(selectedNote, noteListResult)) {
        setSelectedNote(null);
      } else {
        const lSelectedNote = noteListResult.find(
          (note: NoteResult) => note.id === selectedNote.id
        );
        if (!isNoteSame(selectedNote, lSelectedNote)) {
          setSelectedNote(lSelectedNote);
        }
      }
    }
  }, [selectedNote, noteListResult]);

  const handleNoteClick = (note: NoteResult) => {
    setSelectedNote(note);
  };

  return (
    <Div>
      <NoteInfoListDiv>
        {noteListResult !== null && (
          <>
            <h1> Notes</h1>
            <b>{noteListResult.length} notes</b>
            <NoteListDiv>
              <SearchResultList<NoteResult>
                renderSingle={(note: NoteResult) => (
                  <NoteItem
                    note={note}
                    onNoteClick={handleNoteClick}
                    key={`note_${note.id}`}
                  />
                )}
                results={noteListResult}
              />
            </NoteListDiv>
          </>
        )}
      </NoteInfoListDiv>
      <NoteSelectionDiv>
        <NotePanelDiv>
          {selectedNote ? <NoteRead note={selectedNote} /> : <NoNoteInfo />}
        </NotePanelDiv>
      </NoteSelectionDiv>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 0;
  height: 100%;
  margin: 0;
  padding-bottom: 0;
  overflow: hidden;
`;

const NoteInfoListDiv = styled.div`
  width: 100%;
  padding-right: 0%;
  height: 100%;
  overflow: auto;
  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    &&& {
      width: 25%;
      padding-right: 2.5%;
    }
  }
`;

const NoteListDiv = styled.div`
  margin-top: 2rem;
`;

const NoteSelectionDiv = mobHide(styled.div`
  flex-grow: 1;
  max-width: calc(100% - 25% - 2.5%);
`);

const NotePanelDiv = styled.div`
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  padding: 1rem;
  height: calc(100% - 1rem - 2% - 12px - 2px);
  overflow: auto;
  margin-bottom: 2%;
`;

export default NoteList;
