import { Editor } from "@tinymce/tinymce-react";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import styled from "styled-components";
import { ReactRefI } from "type/ref.type";

type TinyMCEEditorProps = {
  initialValue?: string;
  initialHeight?: number;
  editorRef: ReactRefI;
};

const TinyMCEEditor = ({
  initialValue,
  initialHeight,
  editorRef,
}: TinyMCEEditorProps) => {
  return (
    <Div>
      <Editor
        apiKey="qpvp98mo5biolpjz1dz7ium280jrrl9u0xuibmdt5sz6fcqd"
        onInit={(evt, editor) => {
          editorRef.current = editor;
          editor.targetElm.autofocus = true;
          if (editor.iframeElement) {
            // editor.iframeElement.focus();
            if (editor.iframeElement.contentDocument) {
              const contentLength =
                editor.iframeElement.contentDocument.body.textContent?.length;
              // console.log("length: ", contentLength);
            }
          }
          // console.log("TinyMCEEditor", editor.targetElm.autofocus);
        }}
        initialValue={initialValue}
        init={{
          width: "100%",
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
            "codesample",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help | " +
            "codesample | table | image",
          content_style: `
            body {
              font-family: Arial, sans-serif;
              font-size: 14px;
              line-height: 1.6;
              padding: 10px;
              background-color: #f9f9f9;
            }
            pre, code {
              font-family: "Courier New", Courier, monospace;
              font-size: 13px;
              background-color: #2d2d2d;
              color: #f8f8f2;
              padding: 10px;
              border-radius: 5px;
              overflow-x: auto;
            }
            pre {
              margin: 0;
            }
          `,
          codesample_global_prismjs: true,
          codesample_languages: [
            { text: "HTML/XML", value: "markup" },
            { text: "JavaScript", value: "javascript" },
            { text: "CSS", value: "css" },
            { text: "Bash", value: "bash" },
            { text: "Python", value: "python" },
            { text: "Java", value: "java" },
          ],
        }}
      />
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  div.tox-tinymce {
    height: calc(55vh + 1rem) !important;
  }
  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    div.tox-tinymce {
      height: 70vh !important;
    }
  }
`;

export default TinyMCEEditor;
