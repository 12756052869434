import { arrSameElements } from "common/common";
import { NoteResult } from "./dto/NoteResult";

export function isNoteSame(note1: NoteResult, note2: NoteResult): boolean {
  return (
    note1.author === note2.author &&
    note1.title === note2.title &&
    note1.note === note2.note &&
    arrSameElements<string>(note1.authors, note2.authors)
  );
}
