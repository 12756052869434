import { NoteResult } from "common/note/dto/NoteResult";

export function justifyContentGet(
  mobDesFullSpaceBetween?: boolean,
  fullSpaceBetween?: boolean,
  oneNav?: boolean
): string {
  let justifyContent = "start";
  if (fullSpaceBetween || mobDesFullSpaceBetween) {
    justifyContent = "space-between";
  } else if (oneNav) {
    justifyContent = "end";
  }
  return justifyContent;
}

export function noteAuthorsGet(note: NoteResult): string {
  let noteAuthors = "";
  if (note.authors.length > 0) {
    noteAuthors = note.authors.join(", ");
  }
  return noteAuthors;
}
