import { authLogout } from "common/auth/auth_api_common";
import React from "react";
import { useNavigate } from "react-router-dom";

type UseLogoutResult = {
  logout: () => void;
  logoutSuccess: boolean;
};

export const useLogout = (): UseLogoutResult => {
  const navigate = useNavigate();
  const [logoutSuccess, setLogoutSuccess] = React.useState<boolean>(false);

  const logout = React.useCallback(async () => {
    try {
      const success = await authLogout();
      if (success) {
        console.log("Successfull logout");
        navigate("/login");
      }
      setLogoutSuccess(success);
    } catch (err) {
      console.error(err);
      setLogoutSuccess(false);
    }
  }, [authLogout, navigate, setLogoutSuccess]);

  return { logout, logoutSuccess };
};
