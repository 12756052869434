import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { ReactRefI } from "type/ref.type";
import InputField from "components/ui/InputField";
import TinyMCEEditor from "components/ui/TinyMCEEditor";

// Mkrstic, Set Default Author = email
import NoteSingleNav from "../NoteSingleNav";
import { titleHeighlight } from "./NoteForm.logic";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import { NoteResult } from "common/note/dto/NoteResult";
import { useNoteFormEditExit } from "./useNoteFormEditExit";

type NoteFormProps = {
  control: any;
  handleSubmit: Function;
  onSubmit: Function;
  getValues: Function;
  defaultValues?: NoteResult;
  editorRef: ReactRefI;
};

const NoteForm = ({
  onSubmit,
  handleSubmit,
  defaultValues,
  control,
  getValues,
  editorRef,
}: NoteFormProps) => {
  const { noteFormEditExit } = useNoteFormEditExit();

  const titleDefault = defaultValues?.title ?? "";
  const noteDefault = defaultValues?.note ?? "";

  const exitHandle = () => {
    noteFormEditExit(getValues, editorRef, defaultValues);
  };

  return (
    <DivForm>
      <form>
        <DivTitle>
          <InputField
            name="title"
            control={control}
            label="title"
            defaultValue={titleDefault}
            autofocus={titleHeighlight(titleDefault)}
          />
        </DivTitle>

        <TinyMCEEditor
          //initialHeight={TINY_MCE_INITIAL_HEIGHT}
          editorRef={editorRef}
          initialValue={noteDefault}
        />
        <DivNav>
          <DivSubmit>
            <Button onClick={handleSubmit(onSubmit)} type="submit">
              Submit
            </Button>
            <ExitButton onClick={exitHandle} variant={"outlined"}>
              Exit
            </ExitButton>
          </DivSubmit>
          {defaultValues?.id && (
            <NoteSingleNav
              note={defaultValues}
              hideEditNav={true}
              hideReadNav={true}
              alignRightOneNavigation
            />
          )}
        </DivNav>
        {/* <DivAutorField>
          <InputField
            name="author"
            control={control}
            label="author"
            defaultValue={authorDefault}
          />
        </DivAutorField> */}
      </form>
    </DivForm>
  );
};

const DivForm = styled.div`
  margin-top: 0.75rem;
  width: 100%;
`;

const DivSubmit = styled.div`
  margin-right: 2rem;
`;

const DivField = styled.div`
  margin-top: 1rem;
  width: 100%;
  &&& div {
    width: 100%;
  }
`;

const DivTitle = styled(DivField)`
  margin-top: 0rem;
  margin-bottom: 0.5rem;

  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    margin-top: 0rem;
  }
`;

const DivAutorField = styled(DivField)`
  visibility: hidden;
`;

const DivNav = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const ExitButton = styled(Button)`
  margin-left: 0.5rem;
`;

export default NoteForm;
