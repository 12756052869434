import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import PolicyIcon from "@mui/icons-material/Policy";
import LoginIcon from "@mui/icons-material/Login";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LogoutIcon from "@mui/icons-material/Logout";

type NavElement = {
  title: string;
  path: string;
  image?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
};

export const NAV_ELEMENTS: {
  [name: string]: NavElement;
} = {
  START: { title: "Home", path: "/", image: HomeIcon },
  NOTE_NEW: { title: "New", path: "/note/new", image: AddIcon },
  NOTE_LIST: { title: "Notes", path: "/note/list" },
  ABOUT: { title: "About", path: "/about", image: QuestionMarkIcon },
  LOGOUT: { title: "Logout", path: "/logout", image: LogoutIcon },
  LOGIN: { title: "Login", path: "/login", image: LoginIcon },
  TERMS_OF_SERVICE: {
    title: "Terms of Service",
    path: "/terms",
    image: PolicyIcon,
  },
};
