export type UrlBases = {
  auth: string;
  apkores: string;
};

export type ApiType = "auth" | "apkores";

export const apiUrlBaseGet = (): string => {
  console.log(
    "process.env.REACT_APP_API_URL_BASE",
    process.env.REACT_APP_API_URL_BASE
  );

  const urlBase = process.env.REACT_APP_API_URL_BASE ?? "http://localhost:3001";

  return urlBase;
};

export const apiUrlGet = (path: string = ""): string => {
  const urlBase: string = apiUrlBaseGet();
  const url = urlBase + "/" + path;
  return url;
};
