import React from "react";
import styled from "styled-components";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import { justifyContentGet } from "./NoteSingleNav.logic";

const DivNoteSingleNav = styled.div<{
  fullSpaceBetweenItems?: boolean;
  mobFullSpaceBetweenItems?: boolean;
  desFullSpaceBetweenItems?: boolean;
  oneNav?: boolean;
}>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: ${(props) =>
    justifyContentGet(
      props.mobFullSpaceBetweenItems,
      props.fullSpaceBetweenItems,
      props.oneNav
    )};
  svg {
    margin-right: 2rem;
    cursor: pointer;
  }
  svg:hover {
    opacity: 0.8;
  }
  &&& > svg:first-of-type {
    margin-left: 0;
  }
  &&& > svg:last-of-type {
    margin-right: 0rem;
  }

  .people-icon {
    margin-left: auto; /* Push the PeopleIcon to the far right */
    margin-right: 0;
  }

  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    &&& {
      justify-content: ${(props) =>
        justifyContentGet(
          props.desFullSpaceBetweenItems,
          props.fullSpaceBetweenItems,
          props.oneNav
        )};
    }
  }
`;

export default DivNoteSingleNav;
