import React from "react";

import styled from "styled-components";

// Mkrstic, to show User Login in Menu
import { AuthResponse } from "common/auth/auth_api_common";
import NotetakeGradientImage from "components/common/image/NotetakeGradientImage";

type AuthUserProps = {
  authCurrent: AuthResponse;
};

const AuthUser = ({ authCurrent }: AuthUserProps) => {
  return (
    <DivCurrentUser>
      <NotetakeGradientImage height="48px" />
      <DivViewName>{authCurrent?.userViewName ?? ""}</DivViewName>
      <SpanUserEmail>{authCurrent?.userViewEmail ?? ""}</SpanUserEmail>
    </DivCurrentUser>
  );
};

const DivViewName = styled.div`
  margin-top: 1rem;
`;

const DivCurrentUser = styled.div`
  font-size: 1rem;
  text-align: left;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 1.5rem;
`;

const SpanUserEmail = styled.span`
  font-size: 0.8rem;
`;

export default AuthUser;
