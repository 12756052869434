import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const NoNoteInfo = () => {
  return (
    <Div>
      Seems as you currently have no notes.
      <br />
      Please <NoteNavLink to="/note/new">add one</NoteNavLink>.
    </Div>
  );
};

const Div = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
`;

const NoteNavLink = styled(NavLink)`
  text-decoration: none;
`;

export default NoNoteInfo;
