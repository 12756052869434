export function footerDesMobValueGet(
  defaultValue: string,
  desMob?: string,
  bottom?: string
): string {
  let res = defaultValue;
  if (desMob) {
    res = desMob;
  } else if (bottom) {
    res = bottom;
  }

  return res;
}
