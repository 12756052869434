import useConfirm, { ConfirmDialogOptions } from "common/modal/ConfirmDialog";
import React from "react";

type ConditionalConfirmDialogOptions = ConfirmDialogOptions & {
  preCheckCondition: () => boolean;
};

type UseConditionalConfirmResult = {
  confirm: (options: ConditionalConfirmDialogOptions) => Promise<boolean>;
};

export const useConditionalConfirm = (): UseConditionalConfirmResult => {
  const actionConfirm = useConfirm();
  const confirm = async (options: ConditionalConfirmDialogOptions) => {
    let result = false;
    if (options.preCheckCondition()) {
      result = true;
    }
    if (!result) {
      result = await actionConfirm({
        title: options.title,
        message: options.message,
      });
    }

    return result;
  };

  return { confirm };
};
