// React Hook to share the Note with another User, 2025-01-07
//
import { useMutation } from "@tanstack/react-query";
import { noteLeave, noteShare } from "common/api/note/note_share";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";

type UseNoteShareParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
};

type UseNoteLeaveParams = {
  invalidateKeys?: string[];
  invalidateKeysArray?: string[][];
};

export const useNoteShare = (hookParams?: UseNoteShareParams) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: noteShareMutate } = useMutation({
    mutationFn: async (params: any) =>
      await noteShare(params.id, params.updatePayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }
    },
  });

  return { noteShareMutate };
};

export const useNoteLeave = (hookParams?: UseNoteLeaveParams) => {
  const { invalidateQueriesByKeys, invalidateQueriesArraysByKeys } =
    useInvalidateQueryByKeys();
  const { mutate: noteLeaveMutate } = useMutation({
    mutationFn: async (params: any) =>
      await noteLeave(params.id, params.updatePayload),
    onSuccess: () => {
      if (hookParams?.invalidateKeys) {
        invalidateQueriesByKeys(hookParams.invalidateKeys);
      } else if (hookParams?.invalidateKeysArray) {
        invalidateQueriesArraysByKeys(hookParams.invalidateKeysArray);
      }
    },
  });

  return { noteLeaveMutate };
};
