import React from "react";
import {
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

// Mkrstic, to show User Login in Menu
import { useAuthCurrent, useIsAuthenticated } from "hooks/auth/auth_current";
import ListLinkItem, { ListLinkStyleItem } from "./ListLinkItem";
import { NAV_ELEMENTS } from "constant/navigation";
import { useLogout } from "hooks/auth/useLogout";
import AuthUser from "components/common/user/AuthUser";

type MainNavigationDrawerProps = {
  showMenu: boolean;
};

const MainNavigationDrawer = ({ showMenu }: MainNavigationDrawerProps) => {
  const authCurrent = useAuthCurrent();
  const { isAuthenticated } = useIsAuthenticated();
  const { logout } = useLogout();

  return showMenu ? (
    <div>
      <Toolbar />
      {authCurrent && <AuthUser authCurrent={authCurrent} />}

      <Divider />
      {
        <>
          <List>
            {isAuthenticated && (
              <>
                <ListLinkItem
                  path={NAV_ELEMENTS.START.path}
                  label={NAV_ELEMENTS.START.title}
                  LinkImage={NAV_ELEMENTS.START.image}
                />
                <Divider />
                <ListLinkItem
                  path={NAV_ELEMENTS.NOTE_NEW.path}
                  label={NAV_ELEMENTS.NOTE_NEW.title}
                  LinkImage={NAV_ELEMENTS.NOTE_NEW.image}
                />
                <Divider />
                <ListLinkItem
                  path={NAV_ELEMENTS.ABOUT.path}
                  label={NAV_ELEMENTS.ABOUT.title}
                  LinkImage={NAV_ELEMENTS.ABOUT.image}
                />
                <Divider />
                <ListLinkStyleItem key="logout" disablePadding>
                  <LogoutIcon className="nav-icon" />
                  <ListItemButton>
                    <ListItemText primary="Logout" onClick={logout} />
                  </ListItemButton>
                </ListLinkStyleItem>
                <Divider />
              </>
            )}
            {!isAuthenticated && (
              <>
                <ListLinkItem
                  path={NAV_ELEMENTS.LOGIN.path}
                  label={NAV_ELEMENTS.LOGIN.title}
                  LinkImage={NAV_ELEMENTS.LOGIN.image}
                />
                <Divider />
              </>
            )}
            <ListLinkItem
              path={NAV_ELEMENTS.TERMS_OF_SERVICE.path}
              label={NAV_ELEMENTS.TERMS_OF_SERVICE.title}
              LinkImage={NAV_ELEMENTS.TERMS_OF_SERVICE.image}
            />
          </List>
        </>
      }
    </div>
  ) : null;
};

export default MainNavigationDrawer;
