export const envTypeDomainGet = (name: string) => {
  let envType = "";
  if (
    typeof process !== "undefined" &&
    typeof process.env !== "undefined" &&
    typeof process.env.NODE_ENV !== "undefined" &&
    process.env.NODE_ENV === "test"
  ) {
    envType = "test";
  } else {
    const loc = window.location.href + "";
    if (loc.includes("localhost")) {
      envType = "dev";
    } else if (loc.includes("t." + name)) {
      envType = "local_address";
    } else if (loc.includes(name)) {
      envType = "production";
    }
  }
  return envType;
};

export function arrSameElements<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const arrSet = new Set([...arr1, ...arr2]);
  return arrSet.size === arr1.length;
}
