import React, { FormEventHandler } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField } from "@mui/material";

export type ListEditProps<T> = {
  renderSingle: Function;
  results: T[];
  inputLabel?: string;
  listRef: React.MutableRefObject<T[]>;
  readMode?: boolean;
};

function ListEdit<T>({
  renderSingle,
  results,
  inputLabel,
  listRef,
  readMode,
}: ListEditProps<T>) {
  const [list, setList] = React.useState<T[]>([...results]);
  React.useEffect(() => {
    listRef.current = list;
  }, [list]);

  const textRef = React.useRef<HTMLInputElement>(null);

  const deleteHandle = (ind: number) => {
    const listNext = [...list];
    listNext.splice(ind, 1);
    setList(listNext);
  };

  const addHandle = () => {
    console.log("addHandle", textRef.current?.value);
    const val = textRef.current?.value as T;
    const listNext = [...list, val];
    if (textRef.current) {
      textRef.current.value = "";
    }
    setList(listNext);
  };

  const textEnterHandle = (ev: any) => {
    if (ev.keyCode === 13) {
      addHandle();
    }
  };

  return (
    <>
      <DivList>
        {list &&
          list.length > 0 &&
          list.map((item: T, ind: number) => {
            return (
              <DivItem key={"list_edit_" + ind}>
                {renderSingle(item, ind)}
                {!readMode && (
                  <RemoveItem
                    onClick={() => deleteHandle(ind)}
                    color="primary"
                  />
                )}
              </DivItem>
            );
          })}
      </DivList>
      {!readMode && (
        <DivAdd>
          <ItemAddInput
            inputRef={textRef}
            label={inputLabel ?? ""}
            onKeyDown={textEnterHandle}
            variant="outlined"
          />
          <Button onClick={addHandle} color="primary">
            OK
          </Button>
        </DivAdd>
      )}
    </>
  );
}

const DivList = styled.div`
  display: flex;
  flex-direction: column;
  color: rgb(100, 100, 100);
`;

const DivItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

const RemoveItem = styled(CloseIcon)`
  cursor: pointer;
  &&& :hover {
    opacity: 0.8;
  }
`;

const DivAdd = styled.div`
  display: flex;
  margin-top: 1.5rem;
`;

const ItemAddInput = styled(TextField)`
  flex-grow: 1;
`;

export default ListEdit;
