import React from "react";

import styled from "styled-components";

type NotetakeGradientImageProps = {
  height?: string;
};

const NotetakeGradientImage = ({ height }: NotetakeGradientImageProps) => {
  return (
    <>
      <Image src="/images/notetake_gradient.png" height={height} />
    </>
  );
};

const Image = styled.img<{ height?: string }>`
  height: ${(props) => (props.height ? props.height : "48px")};
`;

export default NotetakeGradientImage;
