import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

// Added to support Sharing, 2025-01-06
import ShareIcon from "@mui/icons-material/Share";
import PeopleIcon from "@mui/icons-material/People";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { Tooltip } from "@mui/material";
import { NavLink } from "react-router-dom";

import { QUERY_KEYS } from "constant/react_query";
import useConfirm from "common/modal/ConfirmDialog";
import { NoteResult } from "common/note/dto/NoteResult";
import { useNoteDelete } from "hooks/note/useNoteDelete";

import { noteLinkSingle } from "common/note/note_link_common";

import NoteShareDialog from "./NoteShareDialog";
import DivNoteSingleNav from "./NoteSingleNav.style";
import { noteAuthorsGet } from "./NoteSingleNav.logic";
import { useAuthCurrentIsNoteAuthor } from "hooks/auth/note_auth";

type NoteSingleNavProps = {
  note: NoteResult;
  hideEditNav?: boolean;
  hideReadNav?: boolean;
  fullSpaceBetweenItems?: boolean;
  mobFullSpaceBetweenItems?: boolean;
  desFullSpaceBetweenItems?: boolean;
  alignRightOneNavigation?: boolean;
  navigateToListAfterLeave?: boolean;
};

const NoteSingleNav = ({
  note,
  hideEditNav,
  hideReadNav,
  fullSpaceBetweenItems,
  mobFullSpaceBetweenItems,
  desFullSpaceBetweenItems,
  alignRightOneNavigation,
  navigateToListAfterLeave,
}: NoteSingleNavProps) => {
  const { noteDeleteMutate } = useNoteDelete({
    invalidateKeys: [QUERY_KEYS.NOTE_LIST_GET],
  });
  const isNoteAuthor = useAuthCurrentIsNoteAuthor(note);

  const actionConfirm = useConfirm();

  // Added to support Share, 2025-01-06
  const [isDialogOpen, setDialogOpen] = useState(false);

  const deleteHandle = async () => {
    const check = await actionConfirm({
      title: "Note",
      message: "Are you sure you want to delete note?",
    });
    if (check) {
      noteDeleteMutate(note.id);
    }
  };

  const shareHandle = () => {
    setDialogOpen(true);
  };

  return (
    <>
      <DivNoteSingleNav
        fullSpaceBetweenItems={fullSpaceBetweenItems}
        mobFullSpaceBetweenItems={mobFullSpaceBetweenItems}
        desFullSpaceBetweenItems={desFullSpaceBetweenItems}
        oneNav={alignRightOneNavigation && hideEditNav && hideReadNav}
      >
        {!hideEditNav && (
          <NavLink to={noteLinkSingle(note.id, true)}>
            <EditIcon className="green-icon" />
          </NavLink>
        )}
        {!hideReadNav && (
          <NavLink to={noteLinkSingle(note.id)}>
            <ReadMoreIcon className="green-icon" />
          </NavLink>
        )}
        {isNoteAuthor && (
          <DeleteIcon className="green-icon" onClick={deleteHandle} />
        )}
        {isNoteAuthor && (
          <ShareIcon className="green-icon" onClick={shareHandle} />
        )}

        {note.authors.length > 0 && (
          <Tooltip title={noteAuthorsGet(note)}>
            <PeopleIcon
              className="orange-icon people-icon"
              onClick={shareHandle}
            />
          </Tooltip>
        )}
      </DivNoteSingleNav>
      <NoteShareDialog
        isDialogOpen={isDialogOpen}
        note={note}
        closeDialogHandle={() => setDialogOpen(false)}
        navigateToListAfterLeave={navigateToListAfterLeave}
      />
    </>
  );
};

export default NoteSingleNav;
