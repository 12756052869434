// API Call to Share the node with another user, 2025-01-07
//
// Inputs: NodeID and eMail
//
import { AxiosError } from "axios";
import { axiosNotes } from "../axios/notes_axios";

export const noteShare = async (
  id: string,
  updatePayload: any
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosNotes.put(`notes/share/${id}`, updatePayload);
  } catch (error) {
    console.log("Couldn't share: ", error);
  }
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};

export const noteLeave = async (
  id: string,
  updatePayload: any
): Promise<string> => {
  let result: string = "";
  let response: any;
  try {
    response = await axiosNotes.put(`notes/share/${id}/leave`, updatePayload);
  } catch (error) {
    console.log("Couldn't leave: ", error);
  }
  if (response && response.status === 200) {
    result = response.data;
  }

  return result;
};
