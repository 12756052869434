import React, { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import NavItemSmallScreen from "./NavItemSmallScreen";
import { MainNavElement } from "type/navigation/main_navigation.type";
import { NAV_ELEMENTS } from "constant/navigation";
import { useAuthCurrent, useIsAuthenticated } from "hooks/auth/auth_current";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import PolicyIcon from "@mui/icons-material/Policy";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import { useLogout } from "hooks/auth/useLogout";

type NavItemProps = {
  navItem: MainNavElement;
  clickHandle?: () => void;
};

const MainNavigationSmallScreen = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const authCurrent = useAuthCurrent();
  const { isAuthenticated } = useIsAuthenticated();

  const menuClickHandle = useCallback(() => setShowMenu(false), []);

  const { logout } = useLogout();

  const menuShowClickHandle = () => {
    setShowMenu((currentValue: boolean) => {
      return !currentValue;
    });
  };

  const NavItem = ({ navItem, clickHandle }: NavItemProps) => {
    return (
      <NavItemSmallScreen
        path={navItem.path}
        title={navItem.title}
        clickHandle={clickHandle ?? menuClickHandle}
      />
    );
  };

  return (
    <DivSmallNav>
      <DivHeader>
        <Brand href="/">
          Not&AElig;ke
          <br />
          <SpanSmallEmail>
            {authCurrent?.userViewEmail ?? "guest"}
          </SpanSmallEmail>
        </Brand>
        <DivButton>
          <IconButton
            className="small-menu-icon"
            aria-label="navigation bars"
            onClick={menuShowClickHandle}
          >
            <MenuBars icon={faBars as any} />
          </IconButton>
        </DivButton>
      </DivHeader>

      {showMenu && (
        <DivMobileMenu>
          {isAuthenticated && (
            <>
              <DivMobileMenuItem>
                <HomeIcon />
                <NavItem navItem={NAV_ELEMENTS.START} />
              </DivMobileMenuItem>
              <DivMobileMenuItem>
                <AddIcon />
                <NavItem navItem={NAV_ELEMENTS.NOTE_NEW} />
              </DivMobileMenuItem>
              <DivMobileMenuItem>
                <QuestionMarkIcon />
                <NavItem navItem={NAV_ELEMENTS.ABOUT} />
              </DivMobileMenuItem>
              <DivMobileMenuItem>
                <LogoutIcon />
                <NavItem
                  navItem={{ ...NAV_ELEMENTS.LOGOUT, path: "" }}
                  clickHandle={logout}
                />
              </DivMobileMenuItem>
            </>
          )}
          {!isAuthenticated && (
            <>
              <DivMobileMenuItem>
                <LoginIcon />
                <NavItem navItem={NAV_ELEMENTS.LOGIN} />
              </DivMobileMenuItem>
            </>
          )}
          <DivMobileMenuItem>
            <PolicyIcon />
            <NavItem navItem={NAV_ELEMENTS.TERMS_OF_SERVICE} />
          </DivMobileMenuItem>
        </DivMobileMenu>
      )}
    </DivSmallNav>
  );
};

const SpanSmallEmail = styled.span`
  font-size: 0.8rem;
  font-weight: 100;
`;

const DivSmallNav = styled.div`
  /* Mkrstic, fix horizontal scroll */
  display: none;
  @media (max-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    display: inherit;
  }

  background-color: rgba(118, 65, 65, 1); /* Set navbar background color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Drop shadow */
  position: sticky; /* Optional: Make it stick to the top */
  top: 0;
  z-index: 1000; /* Ensure it stays above other elements */
  color: rgb(65, 118, 118);
`;

const Brand = styled.a`
  /* Mkrstic, fix horizontal scroll */
  padding: 0.5rem 1rem;

  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: white; /* Adjust color as needed */
`;

const DivHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

const DivMobileMenu = styled.div`
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
  margin-right: 0rem;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;

  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: calc(
    100% - 1rem
  ); /* Prevent the menu from going outside the viewport */
  overflow-x: hidden; /* Prevent horizontal overflow */
  overflow-y: auto; /* Allow vertical scrolling if content exceeds height */
  background-color: rgba(118, 65, 65, 1);
  a {
    color: white;
  }
  // border-bottom: 0.2rem solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 10px;
`;

const DivMobileMenuItem = styled.div`
  display: flex;
  margin-top: 1rem;

  svg {
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    color: white;
  }
`;

const DivButton = styled.div`
  margin-right: 1.5rem;
  text-align: right;
`;

const MenuBars = styled(FontAwesomeIcon)`
  color: white;
`;

export default MainNavigationSmallScreen;
