import { NavigateFunction } from "react-router-dom";
import { noteLinkSingle } from "common/note/note_link_common";
import { NoteResult } from "common/note/dto/NoteResult";

export function titleHeighlight(title: string): boolean {
  return !title;
}

export function isNewNote(noteValues?: NoteResult): boolean {
  return typeof noteValues === "undefined";
}

export function exitNoteForm(
  navigate: NavigateFunction,
  noteValues?: NoteResult
) {
  if (isNewNote(noteValues)) {
    navigate("/");
  } else {
    const path = noteLinkSingle(noteValues?.id + "");
    navigate(path);
  }
}

type NoteEditCompare = {
  title: string;
  note: string;
};

export function noteIsEdited(
  isNew: boolean,
  noteEdit: NoteEditCompare,
  noteInitial?: NoteEditCompare
): boolean {
  let result = false;
  if (isNew) {
    if (noteEdit.title !== "" || noteEdit.note !== "") {
      result = true;
    }
  } else if (noteInitial) {
    if (
      noteEdit.title !== noteInitial.title ||
      noteEdit.note !== noteInitial.note
    ) {
      result = true;
    }
  }

  return result;
}
