import React from "react";

type UseScrollDownEdgeResult = {
  scrollHandle: React.UIEventHandler<HTMLElement>;
  nearEdge: boolean;
};

const EDGE_TRESHOLD_DEFAULT = 20;

export function useScrollDownEdge(
  edgeTreshold?: number
): UseScrollDownEdgeResult {
  const [nearEdge, setNearEdge] = React.useState<boolean>(false);
  const treshold = React.useMemo(
    () =>
      typeof edgeTreshold !== "undefined"
        ? edgeTreshold
        : EDGE_TRESHOLD_DEFAULT,
    [edgeTreshold]
  );

  const scrollHandle: React.UIEventHandler<HTMLElement> = (
    ev: React.UIEvent<HTMLElement>
  ) => {
    const target = ev.target as HTMLElement;
    // console.log(
    //   "ev",
    //   target.scrollTop,
    //   target.scrollHeight,
    //   target.clientHeight,
    //   target.scrollHeight - target.clientHeight
    // );
    if (
      target.scrollHeight - target.clientHeight - target.scrollTop <
      treshold
    ) {
      if (!nearEdge) {
        setNearEdge(true);
      }
    } else {
      if (nearEdge) {
        setNearEdge(false);
      }
    }
  };

  return {
    scrollHandle,
    nearEdge,
  };
}
