import { NoteResult } from "./dto/NoteResult";

export const noteFormDefaultValuesGet = (note?: NoteResult) => {
  if (note) {
    return {
      title: note.title,
      author: note.author,
      note: note.note,
      id: note.id,

      // Added to support Share, 2025-01-07
      authors: note.authors,
      createdAt: note.createdAt,
      updatedAt:
        typeof note.updatedAt === "number"
          ? note.updatedAt + ""
          : note.updatedAt,
    };
  } else {
    return {
      title: "",
      author: "",
      // Added to support Share, 2025-01-07
      authors: [],
      note: "",
      id: "",
      createdAt: "",
      updatedAt: "",
    };
  }
};
