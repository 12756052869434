import styled from "styled-components";
import { NoteResult } from "common/note/dto/NoteResult";
import { Typography } from "@mui/material";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";

type NoteItemViewProps = {
  note: NoteResult;
};

const NoteItemView = ({ note }: NoteItemViewProps) => {
  return (
    <DivNote>
      <DivNoteHeader>
        <TitleTypography>{note.title}</TitleTypography>
        <DateTypography>
          {new Date(note.updatedAt).toLocaleDateString()}
        </DateTypography>
      </DivNoteHeader>
    </DivNote>
  );
};

const DivNote = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 0.15rem solid rgb(118, 65, 65);
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    &&& {
      margin-bottom: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }

  // &&& :hover {
  //   background-color: rgba(65, 118, 118, 0.3);
  // }
`;

const DivNoteHeader = styled.div`
  display: flex;
  align-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: calc(100% - 1rem);
  cursor: pointer;
`;

const TitleTypography = styled(Typography)`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(65, 118, 118);
  flex-grow: 1;
  margin-right: 0.2rem;
`;

const DateTypography = styled(Typography)`
  font-size: 0.75rem;
  color: rgb(100, 100, 100);
`;

export default NoteItemView;
