import { NoteResult } from "common/note/dto/NoteResult";
import { ReactRefI } from "type/ref.type";
import { exitNoteForm, isNewNote, noteIsEdited } from "./NoteForm.logic";
import { useNavigate } from "react-router-dom";
import { useConditionalConfirm } from "hooks/check_confirm/useConditionalConfirm/useConditionalConfirm";

type UseNoteFormEditExitResult = {
  noteFormEditExit: Function;
};

export function useNoteFormEditExit(): UseNoteFormEditExitResult {
  const navigate = useNavigate();
  const { confirm } = useConditionalConfirm();

  const noteFormEditExit = async (
    getValues: Function,
    editorRef: ReactRefI,
    defaultValues: NoteResult | undefined
  ) => {
    const titleDefault = defaultValues?.title ?? "";
    const noteDefault = defaultValues?.note ?? "";

    console.log("exitHandle, getValues(title)", getValues("title"));
    const preCheckCondition = () => {
      console.log("preCheckCondition");
      const isEdited = noteIsEdited(
        isNewNote(defaultValues),
        {
          title: getValues("title"),
          note: editorRef.current.getContent(),
        },
        {
          title: titleDefault,
          note: noteDefault,
        }
      );
      return !isEdited;
    };
    const exitCheck = await confirm({
      title: "Exit confirm",
      message:
        "Note has been changed. Do you still want to exit and lost updated data?",
      preCheckCondition,
    });

    if (exitCheck) {
      exitNoteForm(navigate, defaultValues);
    }
  };

  return { noteFormEditExit };
}
