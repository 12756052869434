import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import React from "react";
import styled from "styled-components";
import { footerDesMobValueGet } from "./FooterFade.logic";
type FooterFadeProps = {
  bottom?: string;
  bottomDes?: string;
  bottomMob?: string;
  height?: string;
  heightDes?: string;
  heightMob?: string;
  width?: string;
  widthDes?: string;
  widthMob?: string;
};

const FooterFade = ({
  bottom,
  bottomDes,
  bottomMob,
  height,
  heightDes,
  heightMob,
  width,
  widthDes,
  widthMob,
}: FooterFadeProps) => {
  return (
    <>
      <DivFooterFade
        bottomDes={footerDesMobValueGet("0rem", bottomDes, bottom)}
        bottomMob={footerDesMobValueGet("0rem", bottomMob, bottom)}
        heightDes={footerDesMobValueGet("2rem", heightDes, height)}
        heightMob={footerDesMobValueGet("2rem", heightMob, height)}
        widthDes={footerDesMobValueGet("100%", widthDes, width)}
        widthMob={footerDesMobValueGet("100%", widthMob, width)}
      />
    </>
  );
};

const DivFooterFade = styled.div<{
  bottomDes: string;
  bottomMob: string;
  heightDes: string;
  heightMob: string;
  widthDes: string;
  widthMob: string;
}>`
  position: absolute;
  height: ${(props) => props.heightMob};
  bottom: ${(props) => props.bottomMob};
  width: ${(props) => props.widthMob};
  background-image: linear-gradient(to bottom, transparent, #f9f9f9);

  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    &&& {
      height: ${(props) => props.heightDes};
      bottom: ${(props) => props.bottomDes};
      width: ${(props) => props.widthDes};
    }
  }
`;

export default FooterFade;
