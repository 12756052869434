import React, { useState } from "react";

import { QUERY_KEYS } from "constant/react_query";
import { NoteResult } from "common/note/dto/NoteResult";

import { useNoteLeave, useNoteShare } from "hooks/note/useNoteShare";

// Import for dialog and input handling, 2025-01-06
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ListEdit from "components/common/util/list/ListEdit/ListEdit";
import { useAuthCurrentIsNoteAuthor } from "hooks/auth/note_auth";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

type NoteShareDialogProps = {
  note: NoteResult;
  isDialogOpen: boolean;
  closeDialogHandle: () => void;
  navigateToListAfterLeave?: boolean;
};

const NoteShareDialog = ({
  note,
  isDialogOpen,
  closeDialogHandle,
  navigateToListAfterLeave,
}: NoteShareDialogProps) => {
  const { noteShareMutate } = useNoteShare({
    invalidateKeysArray: [[QUERY_KEYS.NOTE_LIST_GET], [QUERY_KEYS.NOTE_GET]],
  });
  const { noteLeaveMutate } = useNoteLeave({
    invalidateKeys: [QUERY_KEYS.NOTE_LIST_GET],
  });
  const navigate = useNavigate();

  const isNoteAuthor = useAuthCurrentIsNoteAuthor(note);

  const [email, setEmail] = useState("");

  const authorsRef = React.useRef<string[]>(note.authors);

  const handleDialogClose = () => {
    setEmail("");
    closeDialogHandle();
  };

  const handleShare = async () => {
    // console.log("authorsRef.current", authorsRef.current);

    try {
      noteShareMutate({
        id: note.id,
        updatePayload: { emails: authorsRef.current },
      });
      console.info(
        `Shared note ${note.id} with: ${authorsRef.current.join(", ")}`
      );
      handleDialogClose();
      toast.success("Note shared.");
    } catch (error) {
      console.error("Failed to share note:", error);
    }
  };

  const handleLeaveAccess = async () => {
    console.log("handleLeaveAccess");

    try {
      noteLeaveMutate({
        id: note.id,
        updatePayload: {},
      });
      console.info(`User left note ${note.id}`);
      handleDialogClose();
      toast.success("You left note.");
      if (navigateToListAfterLeave) {
        navigate("/");
      }
    } catch (error) {
      console.error("Failed to leave note:", error);
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      sx={{ "& .MuiDialog-paper": { width: "500px", maxWidth: "90%" } }}
    >
      <DialogTitle>
        {isNoteAuthor ? "Share Note" : "Note shared with"}
      </DialogTitle>
      <DialogContent>
        <ListEdit<string>
          renderSingle={(item: string) => <div>{item}</div>}
          results={note.authors}
          inputLabel="Add user email"
          listRef={authorsRef}
          readMode={!isNoteAuthor}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} color="secondary">
          Cancel
        </Button>
        {isNoteAuthor && (
          <Button onClick={handleShare} color="primary">
            Share
          </Button>
        )}
        {!isNoteAuthor && (
          <Button onClick={handleLeaveAccess} color="primary">
            Leave Note
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NoteShareDialog;
