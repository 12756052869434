import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import styled from "styled-components";

export function mobHide(component: any): any {
  const MobHidden = styled(component)`
    @media (max-width: ${SCREEN_WIDTH_BREAKPOINT}) {
      &&& {
        display: none;
      }
    }
  `;

  return MobHidden;
}

export function desHide(component: any): any {
  const DesHidden = styled(component)`
    @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
      &&& {
        display: none;
      }
    }
  `;

  return DesHidden;
}
