import React from "react";
import styled from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";
import { DivMain } from "./style/layout_common.style";
import { Toaster } from "react-hot-toast";
import MainNavigation from "components/navigation/MainNavigation";
import { SCREEN_WIDTH_BREAKPOINT } from "constant/common_constants";
import { useAuthCurrent } from "hooks/auth/auth_current";
import { usePathAuthenticated } from "hooks/auth/usePathAuthenticated";

export default function MainView() {
  const authCurrent = useAuthCurrent();
  const [showContent, setShowContent] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { isPathAuthenticated } = usePathAuthenticated();

  React.useEffect(() => {
    if (authCurrent) {
      if (authCurrent.userViewName !== "" || !isPathAuthenticated) {
        setShowContent(true);
      } else {
        setShowContent(false);
        navigate("/login");
      }
    } else {
      setShowContent(false);
    }
  }, [authCurrent, navigate, isPathAuthenticated]);

  return (
    <>
      {showContent && (
        <DivMain>
          <MainNavigation />
          <Toaster />
          <Div>
            <Outlet />
          </Div>
        </DivMain>
      )}
    </>
  );
}

const Div = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  color: #764141;
  overflow: auto;
  margin-top: 2rem;
  margin-left: 4%;
  width: 92%;
  height: 100%;

  @media (min-width: ${SCREEN_WIDTH_BREAKPOINT}) {
    &&& {
      margin-left: calc(240px + 2%);
      width: calc(96% - 240px);
    }
  }
`;
