import React from "react";
import styled from "styled-components";
import { apiUrlGet } from "common/api/api_common";

const LoginPage = () => {
  const authGoogleUrl = apiUrlGet("auth/google");
  return (
    <DivLogin>
      <DivForm>
        <h1>Not&AElig;ke</h1>
        <div>
          <GoogleNavLink href={authGoogleUrl}>
            <img src="/images/notetake_white.png" alt="Icon" />
            Continue with Google
          </GoogleNavLink>
        </div>
        <TermsLink href="/terms">Terms of Service</TermsLink>
      </DivForm>
    </DivLogin>
  );
};

const DivLogin = styled.div`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
`;

const DivForm = styled.div`
  padding: 4rem;
  text-align: center;
  margin: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
`;

const GoogleNavLink = styled.a`
  text-decoration: none;
  background-color: rgb(65, 118, 118);
  color: white;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  margin-bottom: 5rem;

  &:hover {
    background-color: rgba(65, 118, 118, 0.8);
  }

  img {
    height: 32px;
    margin-right: 0.5rem;
  }
`;

const TermsLink = styled.a`
  text-decoration: none;
  color: rgb(65, 118, 118);
  font-size: 0.8rem;

  &:hover {
    color: rgba(65, 118, 118, 0.5);
  }
`;

export default LoginPage;
