const TermsOfService = () => {
  return (
    <div>
      <h1>Terms of Service</h1>
      <p>
        <strong>Effective Date:</strong> 29.11.2024.
      </p>
      <p>
        Welcome to NoteTake ("we," "our," or "us"). By using our website and
        services ("Service"), you agree to comply with and be bound by these
        Terms of Service ("Terms"). Please read these Terms carefully before
        using our Service.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using the Service, you agree to be bound by these Terms
        and our Privacy Policy, which is incorporated by reference into these
        Terms. If you do not agree to these Terms, you must not use the Service.
      </p>

      <h2>2. User Accounts</h2>
      <p>
        To use certain features of the Service, you may need to create an
        account. You agree to provide accurate and complete information when
        creating an account and to keep your account details up-to-date. You are
        responsible for maintaining the confidentiality of your account
        credentials and for all activities under your account.
      </p>
      <p>
        You agree to notify us immediately if you become aware of any
        unauthorized use of your account.
      </p>

      <h2>3. Use of the Service</h2>
      <p>
        You may use the Service only for lawful purposes and in accordance with
        these Terms. You agree not to:
      </p>
      <ul>
        <li>
          Violate any applicable local, state, or national laws or regulations.
        </li>
        <li>
          Use the Service in any manner that could damage, disable, or impair
          the Service.
        </li>
        <li>
          Upload, post, or transmit any content that is unlawful, defamatory,
          obscene, or otherwise objectionable.
        </li>
        <li>
          Attempt to gain unauthorized access to the Service or any related
          systems.
        </li>
      </ul>

      <h2>4. Content</h2>
      <p>
        You are responsible for the content you create, store, or share through
        the Service. By using the Service, you grant us a non-exclusive,
        royalty-free license to use, store, and display your content solely for
        the purpose of providing the Service.
      </p>
      <p>
        You retain ownership of your content, but you acknowledge that we may
        need to store and manage your content to provide the Service.
      </p>

      <h2>5. Privacy and Data Protection</h2>
      <p>
        Your use of the Service is governed by our Privacy Policy, which
        explains how we collect, use, and protect your personal data. By using
        the Service, you consent to the practices described in the Privacy
        Policy.
      </p>

      <h2>6. Termination</h2>
      <p>
        We may suspend or terminate your access to the Service at any time,
        without notice, for conduct that we believe violates these Terms or is
        harmful to other users or the Service.
      </p>
      <p>
        Upon termination, you may lose access to your account and content. You
        agree that we will not be liable for any loss of data or other harm
        arising from the termination.
      </p>

      <h2>7. Disclaimers</h2>
      <p>
        The Service is provided "as is" and "as available" without warranties of
        any kind, either express or implied. We do not guarantee that the
        Service will be error-free, secure, or uninterrupted. Your use of the
        Service is at your own risk.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, we will not be liable for any
        indirect, incidental, special, or consequential damages arising from
        your use of the Service, even if we have been advised of the possibility
        of such damages.
      </p>

      <h2>9. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless [Your App Name] and
        its affiliates, employees, agents, and licensors from any claims,
        damages, liabilities, and expenses arising from your use of the Service
        or violation of these Terms.
      </p>

      <h2>10. Modifications to Terms</h2>
      <p>
        We reserve the right to modify or update these Terms at any time. Any
        changes will be posted on this page with an updated effective date. Your
        continued use of the Service after the changes are posted constitutes
        your acceptance of the revised Terms.
      </p>

      <h2>11. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of [Your Jurisdiction], without regard to its conflict of law
        principles.
      </p>

      <h2>12. Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms, please contact
        us at:
      </p>
      <p>Email: terms@notetake.rs</p>
    </div>
  );
};

export default TermsOfService;
