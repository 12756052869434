import React from "react";
import { NavLink } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIconTypeMap,
} from "@mui/material";

import styled from "styled-components";

import { OverridableComponent } from "@mui/material/OverridableComponent";

const logout: string = process.env.REACT_APP_LOGOUT ?? "";

type ListLinkItemProps = {
  LinkImage?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  path: string;
  label: string;
};

const ListLinkItem = ({ LinkImage, path, label }: ListLinkItemProps) => {
  return (
    <ListLinkStyleItem key={path}>
      {LinkImage && <LinkImage className="nav-icon" />}
      <NavLinkItem to={path}>
        <ListItemButton>
          <ListItemText primary={label} />
        </ListItemButton>
      </NavLinkItem>
    </ListLinkStyleItem>
  );
};

const NavLinkItem = styled(NavLink)`
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
`;

export const ListLinkStyleItem = styled(ListItem)`
  padding-bottom: 0.5rem;
  padding-left: 0rem;
`;

export default ListLinkItem;
