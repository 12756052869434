import React, { useEffect } from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import NoteSingleNav from "components/common/note/NoteSingleNav/NoteSingleNav";
import { NoteResult } from "common/note/dto/NoteResult";
import { useInvalidateQueryByKeys } from "hooks/query_common/useInvalidateQueryByKeys";
import { QUERY_KEYS } from "constant/react_query";
import Prism from "prismjs";
import "prismjs/themes/prism.css";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-javascript";
import FooterFade from "components/common/util/view/FooterFade";
import { useScrollDownEdge } from "hooks/view_util/scroll/useScrollDownEdge/useScrollDownEdge";
type NoteReadProps = {
  note: NoteResult;
};

const NoteRead = ({ note }: NoteReadProps) => {
  const invalidateQuery = useInvalidateQueryByKeys();
  useEffect(() => {
    invalidateQuery.invalidateQueriesByKeys([QUERY_KEYS.NOTE_GET]);
  }, [invalidateQuery]);

  useEffect(() => {
    Prism.highlightAll();
    console.log("NoteRead useEffect");
  }, [note]);

  const { scrollHandle, nearEdge } = useScrollDownEdge();

  if (!note) {
    return <h1>No Notes in DB</h1>; // Fallback UI
  }

  return (
    <Div>
      <div>
        <NoteSingleNav
          note={note}
          hideReadNav={true}
          // mobFullSpaceBetweenItems
          navigateToListAfterLeave={true}
        />
      </div>
      <DivNote onScroll={scrollHandle}>
        <div>
          <h1>{note.title}</h1>
        </div>
        <div>{note.author}</div>
        <DivNoteContent>{parse(note.note)}</DivNoteContent>
      </DivNote>
      {!nearEdge && (
        <FooterFade
          bottomDes="2.5rem"
          bottomMob="1rem"
          heightMob="1.5rem"
          widthDes="calc(100% - 240px - 25% - 2.5% - 2rem)"
          widthMob="92%"
        />
      )}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DivNote = styled.div`
  margin-top: 0.5rem;
  flex-grow: 1;
  overflow: auto;
`;

const DivNoteContent = styled.div`
  word-wrap: break-word;
`;

export default NoteRead;
