import { useLocation } from "react-router-dom";

const PATHS_AUTHENTICATED = ["/", "/note/new", "/about"];

type UsePathAuthenticatedResponse = {
  isPathAuthenticated: boolean;
};

function pathIsAuthenticated(path: string): boolean {
  let isAuthenticated: boolean = false;
  if (PATHS_AUTHENTICATED.includes(path)) {
    isAuthenticated = true;
  } else {
    if (path.indexOf("/note/") === 0) {
      isAuthenticated = true;
    }
  }
  return isAuthenticated;
}

export const usePathAuthenticated = (): UsePathAuthenticatedResponse => {
  const location = useLocation();
  const isPathAuthenticated = pathIsAuthenticated(location.pathname);

  return { isPathAuthenticated };
};
