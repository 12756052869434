import React from "react";

export type PageNavigation = {
  resTotal: number;
  skip: number;
  limit: number;
  pageNavigationHandle: Function;
};

export type SearchResultListProps<T> = {
  renderSingle: Function;
  results: T[];
};

function SearchResultList<T>({
  renderSingle,
  results,
}: SearchResultListProps<T>) {
  return (
    <>
      {results.length > 0 && (
        <div>
          {results.map((result: T) => {
            return renderSingle(result);
          })}
        </div>
      )}
    </>
  );
}

export default SearchResultList;
