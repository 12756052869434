import { useQuery } from "@tanstack/react-query";
import { authCurrentGet } from "common/auth/auth_api_common";
import { QUERY_KEYS } from "constant/react_query";

type UseIsAuthenticatedResponse = {
  isAuthenticated: boolean;
};

export const useAuthCurrent = () => {
  const { data: currentAuth } = useQuery({
    queryKey: [QUERY_KEYS.AUTH_CURRENT_GET],
    queryFn: authCurrentGet,
    staleTime: Infinity,
  });
  return currentAuth;
};

export const useIsAuthenticated = (): UseIsAuthenticatedResponse => {
  const currentAuth = useAuthCurrent();
  const isAuthenticated = currentAuth?.userViewEmail !== "";
  return { isAuthenticated };
};
